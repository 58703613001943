import Vue from "vue";
import Router from "vue-router";
import Login from "@/pages/login/index";
import kuaixunphone from "@/pages/message/addmessage/phone";
import Layout from "@/pages/layout/layout";
Vue.use(Router);

export default new Router({
	routes: [
		{
			path: "/",
			redirect: "/index",
			hidden: true,
			children: [],
		},
		{
			path: "/login",
			component: Login,
			name: "",
			children: [],
		},
		{
			path: "/kuaixunphone",
			component: kuaixunphone,
			name: "",
			children: [],
		},
		{
			path: "/",
			name: "layout",
			component: Layout,
			children: [
				{
					path: "index",
					name: "主页",
					component: () => import("@/pages/home/home.vue"),
				},
				{
					path: "user",
					name: "用户管理",
					component: () => import("@/pages/user/index.vue"),
					children: [
						{
							path: "userlist",
							name: "用户列表",
							component: () => import("@/pages/user/userlist/index.vue"),
						},
						{
							path: "adduser",
							name: "新增用户",
							component: () => import("@/pages/user/adduser/index.vue"),
						},
						{
							path: "category",
							name: "类别管理",
							component: () => import("@/pages/user/category/index.vue"),
						},
						{
							path: "config",
							name: "配置管理",
							component: () => import("@/pages/user/config/index.vue"),
						},
					],
				},
				{
					path: "addpersonal",
					name: "个人用户新增",
					component: () => import("@/pages/user/adduser/addpersonal.vue"),
				},
				{
					path: "addfirm",
					name: "企业用户新增",
					component: () => import("@/pages/user/adduser/addfirm.vue"),
				},
				{
					path: "addnormal",
					name: "普通用户新增",
					component: () => import("@/pages/user/adduser/addnormal.vue"),
				},
				{
					path: "reportinfodetail",
					name: "举报信息详情",
					component: () =>
						import("@/pages/information/reportinfo/reportinfodetail.vue"),
				},
				{
					path: "message",
					name: "资讯管理",
					component: () => import("@/pages/message/index.vue"),
					children: [
						{
							path: "messagelist",
							name: "资讯列表",
							component: () => import("@/pages/message/messagelist/index.vue"),
						},
						{
							path: "addmessage",
							name: "新增资讯",
							component: () => import("@/pages/message/addmessage/index.vue"),
						},
						{
							path: "flashpc",
							name: "快讯模版",
							component: () => import("@/pages/message/addmessage/phone.vue"),
						},
						{
							path: "dellist",
							name: "恢复数据",
							component: () => import("@/pages/message/dellist/index.vue"),
						},
						{
							path: "category",
							name: "类别管理",
							component: () => import("@/pages/message/category/index.vue"),
						},
						{
							path: "special",
							name: "专题管理",
							component: () => import("@/pages/message/special/index.vue"),
						},
						{
							path: "label",
							name: "标签管理",
							component: () => import("@/pages/message/label/index.vue"),
						},
						{
							path: "announcement",
							name: "公告管理",
							component: () => import("@/pages/message/announcement/index.vue"),
						},
						{
							path: "addannouncement",
							name: "公告新增",
							component: () =>
								import("@/pages/message/announcement/addannouncement.vue"),
						},
						{
							path: "tools",
							name: "工具管理",
							component: () => import("@/pages/message/tools/index.vue"),
						},
						{
							path: "addtools",
							name: "新增工具",
							component: () => import("@/pages/message/addtools/index.vue"),
						},
						{
							path: "addLabel",
							name: "新增标签",
							component: () => import("@/pages/message/label/addLabel.vue"),
						},
					],
				},
				,
				{
					path: "event",
					name: "活动管理",
					component: () => import("@/pages/event/index.vue"),
					children: [
						{
							path: "events",
							name: "活动管理",
							component: () => import("@/pages/event/events/index.vue"),
						},

						{
							path: "order",
							name: "订单管理",
							component: () => import("@/pages/event/order/index.vue"),
						},
						{
							path: "addevent",
							name: "新增活动",
							component: () => import("@/pages/event/events/addEvent.vue"),
						},
						{
							path: "sideEvent",
							name: "side活动",
							component: () => import("@/pages/event/sideEvent/index.vue"),
						},
						{
							path: "sideIndex",
							name: "side详情",
							component: () => import("@/pages/event/sideEvent/sideIndex.vue"),
						},
					],
				},
				{
					path: "recommend",
					name: "推荐管理",
					component: () => import("@/pages/recommend/index.vue"),
					children: [
						{
							path: "eventbanner",
							name: "活动广告管理",
							component: () =>
								import("@/pages/recommend/eventbanner/index.vue"),
						},
						{
							path: "eventtop",
							name: "活动置顶管理",
							component: () => import("@/pages/recommend/eventtop/index.vue"),
						},
					],
				},
				{
					path: "examine",
					name: "审核管理",
					component: () => import("@/pages/examine/index.vue"),
					children: [
						{
							path: "examinelist",
							name: "认证审核",
							component: () => import("@/pages/examine/examinelist/index.vue"),
						},
						{
							path: "messageexamine",
							name: "信息审核",
							component: () =>
								import("@/pages/examine/messageexamine/index.vue"),
						},
					],
				},
				{
					path: "information",
					name: "消息管理",
					component: () => import("@/pages/information/index.vue"),
					children: [
						{
							path: "push",
							name: "推送信息",
							component: () => import("@/pages/information/push/index.vue"),
						},
						{
							path: "problemfeedback",
							name: "问题反馈",
							component: () =>
								import("@/pages/information/problemfeedback/index.vue"),
						},
						{
							path: "reportinfo",
							name: "举报信息",
							component: () =>
								import("@/pages/information/reportinfo/index.vue"),
						},
						{
							path: "addPush",
							name: "新增资讯",
							component: () =>
								import("@/pages/information/push/addPush/index.vue"),
						},
						{
							path: "telegram",
							name: "telegram",
							component: () => import("@/pages/information/telegram/index.vue"),
						},
						{
							path: "botArticleDetail",
							name: "新增Bot",
							component: () =>
								import("@/pages/information/telegram/articledetail.vue"),
						},
						{
							path: "botEventDetail",
							name: "新增Bot",
							component: () =>
								import("@/pages/information/telegram/eventdetail.vue"),
						},
					],
				},
				{
					path: "limitsauthority",
					name: "权限管理",
					component: () => import("@/pages/limitsauthority/index.vue"),
					children: [
						{
							path: "personnel",
							name: "人员管理",
							component: () =>
								import("@/pages/limitsauthority/personnel/index.vue"),
						},
						{
							path: "addpersonnel",
							name: "新增人员",
							component: () =>
								import("@/pages/limitsauthority/addpersonnel/index.vue"),
						},
						{
							path: "role",
							name: "角色管理",
							component: () => import("@/pages/limitsauthority/role/index.vue"),
						},
						{
							path: "addrole",
							name: "新增角色",
							component: () =>
								import("@/pages/limitsauthority/addrole/index.vue"),
						},
					],
				},
				{
					path: "webdata",
					name: "运营数据",
					component: () => import("@/pages/webdata/index.vue"),
					children: [
						{
							path: "pvuv",
							name: "全站数据",
							component: () => import("@/pages/webdata/pvuv/index.vue"),
						},
						{
							path: "messageList",
							name: "文章列表",
							component: () => import("@/pages/webdata/messagelist/index.vue"),
						},
						{
							path: "singleList",
							name: "单篇数据",
							component: () => import("@/pages/webdata/singleList/index.vue"),
						},
					],
				},
			],
		},
	],
});
//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
