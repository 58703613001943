<template>
	<div>
		<div class="cropper-content">
			<!-- 剪裁框 -->
			<div class="cropper">
				<img id="cropImg" ref="cropImg" :src="imageUrl" alt="" />
			</div>
			<!-- 预览框 -->
			<div
				class="show-preview"
				:style="{
					overflow: 'hidden',
					margin: '0 25px',
					display: 'flex',
					'align-items': 'center',
				}"
			>
				<div class="preview before" />
			</div>
		</div>
		<div class="footer-btn">
			<!-- 缩放旋转按钮 -->
			<div class="scope-btn">
				<label
					class="btn"
					for="uploads"
					:style="{
						display: 'flex',
						'align-items': 'center',
					}"
					style="margin-right: 10px"
					>选择文件</label
				>
				<input
					type="file"
					id="uploads"
					style="position: absolute; clip: rect(0 0 0 0)"
					accept="image/png, image/jpeg, image/gif, image/jpg"
					@change="selectImg($event)"
				/>
				<el-button
					size="mini"
					type="danger"
					plain
					icon="el-icon-zoom-in"
					@click="cropperzoom(0.05)"
					>放大</el-button
				>
				<el-button
					size="mini"
					type="danger"
					plain
					icon="el-icon-zoom-out"
					@click="cropperzoom(-0.05)"
					>缩小</el-button
				>
				<el-button size="mini" type="danger" plain @click="cropperRotate(-90)"
					>左旋转</el-button
				>
				<el-button size="mini" type="danger" plain @click="cropperRotate(90)"
					>右旋转</el-button
				>
				<el-button size="mini" type="danger" plain @click="bannerScale()"
					>横幅比例</el-button
				>
				<el-button size="mini" type="danger" plain @click="coverScale()"
					>封面比例</el-button
				>
				<el-button size="mini" type="danger" plain @click="customScale()"
					>自由比例</el-button
				>
			</div>

			<!-- 确认上传按钮 -->
			<div class="upload-btn">
				<!-- <el-button type="primary" @click="uploadImg('blob')">上传</el-button> -->
				<!-- <el-button @click="closeCropper">取消</el-button> -->
				<el-button type="primary" :disabled="isDisabled" @click="sureSava()"
					>确认修改</el-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { uploadImage } from "@/api/user";
import * as imageConversion from "image-conversion";
import { ref, watch } from "vue";
export default {
	name: "CropperImageNew",
	props: {
		Name: {
			type: String,
			default: "",
		},
		autoCropWidth: {
			type: Number,
			default: 0,
		},
		autoCropHeight: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			imageUrl: this.Name,
			myCropper: null,
			afterImg: "",
			ScaleX: 1,
			ScaleY: 1,
			fixed: false,
			fixedBox: false,
			inputRotate: 0,
			isDisabled: false,
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.createCropper();
	},
	methods: {
		closeCropper() {
			if (this.myCropper != null) {
				this.myCropper.destroy();
			}
			this.$emit("closeCropper");
		},
		handleChangeRotate(value) {
			this.cropperrotateTo(value);
		},
		createCropper() {
			if (this.myCropper != null) {
				this.myCropper.destroy();
			}
			const image = document.getElementById("cropImg");
			// debugger;
			this.myCropper = new Cropper(image, {
				viewMode: 1,
				dragMode: "move",
				// initialAspectRatio: 1,
				preview: ".before",
				background: false,
				autoCropArea: 1,
				zoomOnWheel: true,
				aspectRatio: this.autoCropWidth / this.autoCropHeight,
				// movable :true,
				// rotatable :true
			});
		},
		//选择图片
		selectImg(event) {
			//读取上传文件
			let reader = new FileReader();
			if (event.target.files[0]) {
				//readAsDataURL方法可以将File对象转化为data:URL格式的字符串（base64编码）
				reader.readAsDataURL(event.target.files[0]);
				reader.onload = (e) => {
					let dataURL = reader.result;
					//将img的src改为刚上传的文件的转换格式
					document.querySelector("#cropImg").src = dataURL;
					this.createCropper();
				};
			}
		},
		// 裁剪
		uploadImgs() {
			this.afterImg = this.myCropper
				.getCroppedCanvas({
					imageSmoothingQuality: "high",
				})
				.toDataURL("image/jpeg");
			this.$emit("getCrop", this.afterImg);
		},
		sureSava() {
			// this.afterImg = this.myCropper
			// 	.getCroppedCanvas({
			// 		imageSmoothingQuality: "high",
			// 	})
			// 	.toDataURL("image/jpeg");
			// this.$emit("upload", this.base64ToBlob(this.afterImg));
			//getCroppedCanvas方法可以将裁剪区域的数据转换成canvas数据

			var that = this;
			var canvas = this.myCropper.getCroppedCanvas({
				maxWidth: 2048,
				maxHeight: 2048,
				fillColor: "#fff",
				imageSmoothingEnabled: true,
				imageSmoothingQuality: "high",
			});
			canvas.toBlob((data) => {
				var scale = 1;
				if (data.size > 1024 * 200) {
					scale = 0.8;
				}
				canvas.toBlob(
					function (img) {
						let formData = new FormData();
						formData.append("file", img, ".jpg");
						uploadImage(formData).then((res) => {
							if (res.code == 200) {
								let str = res.data.url;
								let newStr = str.replace(
									"metaera-test-1318926170.cos.ap-hongkong.myqcloud.com",
									"zy.metaera.media"
								);
								res.data.url = newStr;
								that.$emit("uploadImgSuccess", res.data);
								if (that.myCropper != null) {
									that.myCropper.destroy();
								}
							} else {
								that.$message.error(res.message);
							}
						});
					},
					"image/jpeg",
					scale
				);
			});

			// canvas.toBlob((blob) => {
			// 	let formData = new FormData();
			// 	formData.append("file", blob, ".jpg");
			// 	uploadImage(formData).then((res) => {
			// 		if (res.code == 200) {
			// 			this.$emit("uploadImgSuccess", res.data);
			// 		} else {
			// 			this.$message.error(res.message);
			// 		}
			// 	});
			// });
		},
		base64ToBlob(code) {
			const parts = code.split(";base64,");
			const contentType = parts[0].split(":")[1];
			const raw = window.atob(parts[1]);
			const rawLength = raw.length;

			const uInt8Array = new Uint8Array(rawLength);

			for (let i = 0; i < rawLength; ++i) {
				uInt8Array[i] = raw.charCodeAt(i);
			}
			return new Blob([uInt8Array], {
				type: contentType,
			});
		},
		// 缩放
		cropperzoom(val) {
			this.myCropper.zoom(val);
		},
		// 重置
		cropperReset() {
			this.myCropper.reset();
			this.ScaleX = 1;
			this.ScaleY = 1;
		},
		// 移动
		croppermove(val1, val2) {
			this.myCropper.move(val1, val2);
		},
		// 旋转
		cropperRotate(val) {
			this.myCropper.rotate(val);
		},
		// 绝对角度旋转
		cropperrotateTo(val) {
			this.myCropper.rotateTo(val);
		},
		// X轴翻转
		cropperScaleX() {
			this.ScaleX = -this.ScaleX;
			if (
				this.myCropper.getImageData().rotate === -90 ||
				this.myCropper.getImageData().rotate === 90
			) {
				this.myCropper.scaleY(this.ScaleX);
			} else {
				this.myCropper.scaleX(this.ScaleX);
			}
		},
		// y轴翻转
		cropperScaleY() {
			this.ScaleY = -this.ScaleY;
			if (
				this.myCropper.getImageData().rotate === -90 ||
				this.myCropper.getImageData().rotate === 90
			) {
				this.myCropper.scaleX(this.ScaleY);
			} else {
				this.myCropper.scaleY(this.ScaleY);
			}
		},
		bannerScale() {
			this.myCropper.setAspectRatio(225 / 90);
		},
		coverScale() {
			this.myCropper.setAspectRatio(21 / 13);
		},
		customScale() {
			this.myCropper.setAspectRatio(NaN);
		},
	},
};
</script>
<style scoped>
.cropper {
	border: 1px solid #fff;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
}
.cropper img {
	width: 100%;
	opacity: 0;
}
.cropper-content {
	display: flex;
	display: -webkit-flex;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
}
.cropper-content .cropper {
	width: 550px;
	height: 400px;
	overflow: hidden;
}
.cropper-content .show-preview {
	flex: 1;
	-webkit-flex: 1;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	overflow: hidden;
	/* border: 1px solid #cccccc; */
	background: #cccccc;
	margin-left: 40px;
}
.preview {
	overflow: hidden;
	border: 1px solid #468ac8;
	background: #cccccc;
}
.footer-btn {
	margin-top: 30px;
	display: flex;
	display: -webkit-flex;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
}
.footer-btn .scope-btn {
	display: flex;
	display: -webkit-flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
}

.footer-btn .scope-btn span {
	display: inline-block;
	padding: 10px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	background: #fff;
	cursor: pointer;
}
.footer-btn .scope-btn span i {
	font-size: 32px;
	color: #333333;
}
.footer-btn .upload-btn {
	flex: 1;
	-webkit-flex: 1;
	display: flex;
	display: -webkit-flex;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	margin-right: 25px;
}
.footer-btn .btn {
	outline: none;
	display: inline-block;
	line-height: 1;
	white-space: nowrap;
	cursor: pointer;
	-webkit-appearance: none;
	text-align: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
	-webkit-transition: 0.1s;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 15px;
	font-size: 12px;
	border-radius: 3px;
	color: #fff;
	background-color: #67c23a;
	border-color: #67c23a;
}
.solide {
	margin-left: 25px;
	width: 200px;
}
.before {
	width: 100%;
	height: 100%;
	overflow: hidden;
	/* 这个属性可以得到想要的效果 */
}
</style>
